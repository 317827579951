"use client"

import {useEffect} from "react";
import {usePathname} from "next/navigation"
const MijnMkgSubMenuClient = () => {
    const pathname = usePathname()
    useEffect(() => {
        const submenu = document.getElementsByClassName("submenu");
        for (let i = 0; i < submenu.length; i++) {
            submenu[i].classList.remove("menuListItemActive")
        }
        let element
        if (pathname.includes('mijn-mkg/dashboard')) {
            element = document.getElementById("subMenuDashboard");
        } else if (pathname.includes('mijn-mkg/mijn-gegevens')) {
            element = document.getElementById("subMenuMijnGegevens");
        }  else if (pathname.includes('mijn-mkg/bedrijfsdoelen')) {
            element = document.getElementById("subMenuBedrijfsdoelen");
        } else if (pathname.includes('mijn-mkg/academy')) {
            element = document.getElementById("subMenuAcademy");
        } else if (pathname.includes('mijn-mkg/activiteiten')) {
            element = document.getElementById("subMenuActiviteiten");
        } else if (pathname.includes('mijn-mkg/support')) {
            element = document.getElementById("subMenuSupport");
        } else if (pathname.includes('mijn-mkg/uren')) {
            element = document.getElementById("subMenuUren");
        } else if (pathname.includes('mijn-mkg/beheer')) {
            element = document.getElementById("subMenuBeheer");
        }

        element?.classList?.add("menuListItemActive");
    }, [pathname]);

    return (
        <></>
    )
}

export default MijnMkgSubMenuClient