"use client"
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {components} from "react-select";
import {ImSearch} from "@react-icons/all-files/im/ImSearch";
import {BsFillBellFill} from 'react-icons/bs'
import {MdArrowForwardIos} from 'react-icons/md'
import {MdKeyboardArrowRight} from "@react-icons/all-files/md/MdKeyboardArrowRight";
import classes from './search-bar.module.scss'
import Select from "react-select";
import Link from 'next/link'
import { useSearchParams } from 'next/navigation'
import {Button, Container} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useRouter } from 'next/navigation'
import _ from 'lodash'
import { usePathname } from "next/navigation";
import {useTekstClient} from "hooks/useTekstClient";
import {useAxios} from "hooks/useAxios";
import {useSetSearchParams} from "hooks/useSetSearchParams";



var GLOBALZOEKINPUT = '';

const SearchBar = () => {
    const {tekst, isLoadingTekst} = useTekstClient()
    const searchRef = useRef(null);
    const [inputValue, setInputValue] = useState('');
    const [selectedValue, setSelectedValue] = useState(null);
    const [beschikbareCategorieen, setBeschikbareCategorieen] = useState(null);
    const {Option} = components;
    const [showMenu, setShowMenu] = useState(false);
    // const zoekenbalk = useSelector(state => state.contentZoeken.zoek);
    // const zoekBackendFilters = useSelector(state => state.contentZoeken.backendFilters);
    // const zoekenInCategorie = useSelector(state => state.contentZoeken.categorie);
    // const filterTagGroep = useSelector(state => state.kennisCentrum?.filterTagGroep);
    // const items = useSelector(state => state.kennisCentrum?.kennisItems);
    const [placeholder, setPlaceholder] = useState('Zoek in mijn mkg');
    const [searchbarOptions, setSearchbarOptions] = useState([]);
    const [searchbarIsLoading, setSearchbarIsLoading] = useState(false);
    const [searchbarToken2, setSearchbarToken2] = useState(null);
    const [showModal, setShowModal] = useState(false)
    const searchParams = useSearchParams()
  //  const kennisCentrumParams = useSelector(state => state.kennisCentrum.httpParams); //Object
    const reg = /[^a-zA-Z0-9À-ú\- ]/g
    const router = useRouter()
    const pathName = usePathname();
    const {get} = useAxios()
    const [isClient, setIsClient] = useState(false)


    const {setParams, deleteParams} = useSetSearchParams()


    useEffect(() => {
        setIsClient(true)
    }, [isClient])

    //
    // useEffect(() => {
    //     if (zoekenInCategorie.length > 0) {
    //         setPlaceholder(`Verder zoeken in: ${zoekenInCategorie.map(cat => cat.name).join(' > ')}`)
    //     } else {
    //         setPlaceholder('Zoek in mijn mkg')
    //     }
    // }, [zoekenInCategorie])


    const loadOptions = (inputValueNu) => {
        if (!inputValueNu) {
            setSearchbarOptions([]);
            //setSearchbarIsLoading(false);
            return;
        }

        if (GLOBALZOEKINPUT !== inputValueNu) {
            return
        }

        get(`/wiki/searchbar?zoek=${inputValueNu}`).then((response) => {
                setShowMenu(true);
                let zoekinput = {
                    label: 'Zoek',
                        options: [{soort: 'tekst', id: 0, titel: GLOBALZOEKINPUT}],
                };

                let tmpOptions = [
                    {
                        label: 'Categorieën',
                        options: response.data?.categorieen,
                    }, {
                        label: 'Zoeksuggesties',
                        options: response.data?.taggroep,
                    },
                    {
                        label: 'Populaire Items',
                        options: response.data?.items,
                    }
                ];


                setSearchbarOptions([zoekinput, ...tmpOptions]);
                //loadOptionsTitels(inputValueNu, tmpOptions)
                setSearchbarIsLoading(false);
            });

    };
/*
    const loadOptionsTitels = (inputValueNu, searchbarOptionsDeel1) => {

        if (searchbarToken2 !== null) {
            searchbarToken2.cancel("geannuleerd omdat er een nieuw request wordt verstuurd");
        }
        if (GLOBALZOEKINPUT !== inputValueNu) {
            return
        }

        //Save the cancel token for the current request
        let searchbarTokenMem2 = axios.CancelToken.source()
        setSearchbarToken2(searchbarTokenMem2);

        get(`/wiki/searchbar-titels?zoek=${inputValueNu}`, {cancelToken: searchbarTokenMem2.token})
            .then((response) => {
                setShowMenu(true);
                let zoekinput = {
                    label: 'Zoek',
                    options: [{soort: 'tekst', id: 0, titel: GLOBALZOEKINPUT}],
                };
                let tmpOptions = [zoekinput, ...searchbarOptionsDeel1,
                    {
                        label: 'Populaire Items',
                        options: response.data?.items,
                    }
                ];
                setSearchbarOptions(tmpOptions);
                setSearchbarIsLoading(false);
            });

    };*/
    const setServicesValueDebounced = useMemo(() => _.debounce(loadOptions, 300), []);


    // Zoek-icoon toevoegen aan balk
    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <ImSearch label="zoeken" size={22} style={{color: "#152E4A"}}/>
            </components.DropdownIndicator>
        );
    };

    // Groepen stijlen
    const Group = (props) => {
        return (<div style={{
                borderBottom: `1px solid #ccc `,
            }}>
                <components.Group {...props} />
            </div>
        );
    }

    // Option in dropdown stijlen
    const DropdownOption = props => (
        <Option {...props} key={props.data.titel}>
            {props.data.titel.split('>').map((val, key) => {
                let splitter;

                let inputValueTmp = inputValue.replace(reg, "");
                val = val.replace(reg, "");

                splitter = val.split(new RegExp(inputValueTmp, "ig"));

                // gevonden letters bold maken
                val = splitter.reduce((prev, current, i) => {
                    if (!i) {
                        return [current];
                    }
                    return prev.concat(<b key={inputValue + current}>{inputValue}</b>, current);
                }, [])

                // > in categorie stijlen
                if (key === 0) return val
                else return <><MdKeyboardArrowRight style={{display: "inline"}}/>{val}</>;
            })}
        </Option>
    );

    const handleKeyDown = (e) => {

        if (e.key === 'Enter') {
            // zoeken InputValue
            setShowMenu(false);
            setInputValue(e.target.value);
           // setContentZoekenBalk({soort: 'tekst', id: 0, titel: e.target.value});
            setSelectedValue({soort: 'tekst', id: 0, titel: e.target.value})



            searchRef.current.blur();
            return router.push(`/mijn-mkg/support/kenniscentrum?tekst=${e.target.value}`);
            // if (!_.startsWith(pathName, `/mijn-mkg/support/kenniscentrum`))
            //     return router.push(`/mijn-mkg/support/kenniscentrum?tekst=${e.target.value}`);
        }
    }

    const handleOnChange = (value) => {
        setShowMenu(false);
        setSearchbarIsLoading(false)
        //setContentZoekenBalk(value)
        setSelectedValue(value && value.soort === 'categorie' ? null : value)
        searchRef.current.blur();

        let url = ''

        if(value.soort === 'categorie'){
            url = `/mijn-mkg/support/kenniscentrum?cat=${value.id}`
        }

        if(value.soort === 'tekst'){
            url = `/mijn-mkg/support/kenniscentrum?tekst=${value.titel}`
        }

        if(value.soort === 'taggroep'){
            url = `/mijn-mkg/support/kenniscentrum?taggroep=${value.taggroep}`
        }

        return router.push(url);

    }

    //
    // useEffect(() => {
    //     searchParams.get('tekst') ? setContentZoekenBalk({
    //         soort: 'tekst',
    //         id: 0,
    //         titel: searchParams.get('tekst')
    //     }) : setContentZoekenBalk(null);
    //     searchParams.get('taggroep') ? setContentZoekenBalk({
    //         soort: 'taggroep',
    //         taggroep: parseInt(searchParams.get('taggroep')),
    //         titel: filterTagGroep?.naam
    //     }) : setContentZoekenBalk(null);
    // }, [searchParams])


    return  <div className={classes.searchWrapper}>
        <Container fluid="md" style={{maxWidth: '1140px'}}>
            <div className={classes.searchBarContainer}>
                { isClient && <Select
                    className={classes.searchBarSelect}
                    // isClearable={true}
                    ref={searchRef}
                    cacheOptions
                    maxMenuHeight={'90vh'}
                    value={selectedValue}
                    onFocus={() => {
                        setSelectedValue('')
                    }}
                    isLoading={searchbarIsLoading}
                    openMenuOnFocus={false}
                    openMenuOnClick={false}
                    closeMenuOnSelect={true}
                    components={
                        (!inputValue || inputValue === '') ? {
                            // DropdownIndicator,
                            Menu: () => null,
                            MenuList: () => null,
                        } : {
                            // DropdownIndicator,
                            Group,
                            Option: DropdownOption,
                        }}
                    placeholder={placeholder}
                    onInputChange={(value) => {
                        // value = value.replace(reg, '');
                        GLOBALZOEKINPUT = value;
                        setInputValue(value);
                        if (value === inputValue) return;

                        setSearchbarIsLoading(true);
                        if (value) {
                            setSearchbarOptions([
                                {
                                    label: 'Zoek',
                                    options: [{soort: 'tekst', id: 0, titel: value}],
                                }]);
                        } else {
                            setSearchbarOptions([]);
                        }
                        setServicesValueDebounced(value)
                    }}
                    inputValue={inputValue}
                    options={searchbarOptions}
                    //loadOptions={setServicesValueDebounced}
                    getOptionLabel={option => option.titel}
                    getOptionValue={option => option.id}
                    filterOption={() => true}

                    onChange={handleOnChange}
                    styles={{
                        container: base => ({
                            ...base,
                            // border:  "1px solid #152E4A",

                        }),
                        // dropdownIndicator: base => ({
                        //     ...base,
                        //     backgroundColor: "#FECB47",
                        //     minWidth: "70px",
                        //     display: 'flex',
                        //     justifyContent: 'center',
                        //     alignItems: 'center',
                        //     cursor: 'pointer'
                        // }),
                        indicatorSeparator: base => ({
                            ...base,
                            backgroundColor: "#FFF" // Custom colour
                        }),
                        menuPortal: styles => ({...styles, zIndex: 999}),
                        control: styles => ({
                            ...styles,
                            backgroundColor: 'white',
                            cursor: 'text',
                            border: 'none',
                            borderRadius: 'none'
                        }),
                        menu: styles => ({...styles, zIndex: 999})
                    }}
                    onKeyDown={handleKeyDown}
                    //defaultOptions
                />}
                <Button className={classes.searchBarButton} onClick={() => setShowModal(true)}>
                    <span><BsFillBellFill/></span>
                    <span>{tekst('pagina:menu:SearchBar:Maak een melding')}</span>
                    <span><MdArrowForwardIos/></span>

                </Button>
                <Modal backdrop="static" show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{tekst('pagina:menu:SearchBar:modal:header')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <span>{tekst('pagina:menu:SearchBar:modal:body')} </span><br/>
                            <Link style={{textDecoration: 'underline'}} href={'/mijn-mkg/support/kenniscentrum'}
                                  onClick={() => setShowModal(false)}>{tekst('pagina:support:kenniscentrum:titel')} </Link>
                            <span>{tekst('pagina:menu:SearchBar:modal:body 1')}</span>
                        </div>
                        <br/>
                        <div>{tekst('pagina:menu:SearchBar:modal:footer')}</div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => {
                            if (typeof window === "undefined") return;
                            window.open('https://mkg.topdesk.net', '_blank')
                            setShowModal(false)
                        }}>
                            {tekst('pagina:menu:SearchBar:modal:btnMeldingMaken')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </Container>
    </div>



}
export default SearchBar;