"use client"


import {MenuItem} from "@headlessui/react";
import React, {useEffect, useState} from "react";
import {useRouter} from "next/navigation";
import {useTekstClient} from "../../../hooks/useTekstClient";
import revalidateAction from "../../../lib/actions";
import {useSWRConfig} from "swr"
import SelectBedrijven from "../../SelectAlleBedrijven/SelectBedrijven/SelectBedrijven";
import {useAxios} from "../../../hooks/useAxios";


export function LogOffButton(){

    const router = useRouter();
    const {tekst, isLoadingTekst} = useTekstClient();
    const {post} = useAxios();
    const logOff = async () => {
        const logOff = await post(`${process.env.NEXT_PUBLIC_BACKEND}/api/uitloggen`)
        const {status} = logOff
        if (status === 204) {
            router.push('/mijn-mkg/support');
            router.refresh();
        }
    }

    return  <MenuItem className={`hover:text-cyan`} onClick={logOff}>
        <small
            className="block w-full px-4 py-1 text-left text-xs text-gray-700 data-[focus]:text-kleur-cyan2 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 uppercase underline cursor-pointer"
        >
            {tekst('pagina:menu:uitloggen')}
        </small>
    </MenuItem>
}


export function SelectCompanyButton({namensBedrijf, ingelogdeGebruiker, companies}) {

    const {mutate} = useSWRConfig()
    const [bedrijven, setBedrijven] = useState(null);
    const {tekst, isLoadingTekst} = useTekstClient();
    const {post} = useAxios();



    const handleSaveBedrijf = bedrijf => {
        if (bedrijf != null) {
            post(`${process.env.NEXT_PUBLIC_BACKEND}/api/gebruiker/ik/namensbedrijf/` + bedrijf.id).then(response => {
                if (response.status === 200) {
                    mutate(`${process.env.NEXT_PUBLIC_BACKEND}/api/gebruiker/ik/namensbedrijf`)
                    revalidateAction('namensBedrijf')
                    revalidateAction('actionCounter')
                }
            })
        } else {
            post(`${process.env.NEXT_PUBLIC_BACKEND}/api/gebruiker/ik/namensbedrijf/unset`).then(response => {
                if ([200, 204].includes(response.status)){
                    mutate(`${process.env.NEXT_PUBLIC_BACKEND}/api/gebruiker/ik/namensbedrijf`)
                    revalidateAction('namensBedrijf')
                }
            })
        }
    }


    return <section className={`my-2`}>
        <div
            className={`block px-4 py-3 text-xs text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 border-y border-inherit`}>
            <p>{tekst('algemeen:menu:inloggen_namens')}</p>

            <SelectBedrijven
                getOptionValue={option => option.id} // value is bedrijf, dus hier id
                value={namensBedrijf}
                options={companies}
                isMulti={false}
                isClearable={(ingelogdeGebruiker !== null
                    && ingelogdeGebruiker.hasOwnProperty('groep')
                    && ingelogdeGebruiker.groep.mkger)
                }
                onChange={handleSaveBedrijf}
            />

        </div>
    </section>
}