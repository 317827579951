"use client"
import React from 'react'
import useSWR from "swr";
import {useAxios} from "../../hooks/useAxios";


export const TagAantalStoringen = () =>{
    const {get} = useAxios()
    const fetcher = async (url) => {
        const response = await get(url);
        return response.data
    };

    const keyStoringCounter = '/support/storingen/aantal'
    const { data: storingCounter } = useSWR(keyStoringCounter, fetcher, { refreshInterval: 30000 });

    return storingCounter ? <div
        className="font-bold text-kleur-donkerste-blauw  absolute right-2 top-2 bg-kleur-geel rounded-full w-5 h-5 flex justify-center items-center text-xs">{storingCounter?.aantal}
    </div> : <></>
}