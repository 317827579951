// noinspection JSAnnotator
"use client"
import React, {useEffect, useState} from 'react'
import style from "components/Menu/menu.module.scss";
import Link from "next/link";
import HamburgerOverlayMenu from "./components/HamburgerOverlayMenu";
import {useTekstClient} from "../../hooks/useTekstClient";
import mijnMkgLogo from '@/public/images/MKG-logo-2024_Menu_w118pxXh50px_full-color.png'
import Image from 'next/image'

export const MkgMenuClientSide = () => {

    const {tekst, isLoadingTekst} = useTekstClient()

    const [isTop, setIsTop] = useState(true);

    const handleScroll = () => {
        setIsTop(window.scrollY === 0);
    };



    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);




    return <div  className={`absolute left-0 top-0 inset-0 flex items-center justify-center ${!isTop ? style.containScroll : ''}`}>
        <div className={`${style.container}`}>
            <Link href="/">
                <Image src={mijnMkgLogo} alt={'MKG logo'} style={{padding: "4px 12px 4px 6px"}}/>
            </Link>

            <div className={`${style.menuLinks}`}>
                <Link href={'/#oplossingen'}>{tekst('website:menu:Oplossingen')}</Link>
                <Link href={'/mkg-add-ons'}>{tekst('website:menu:addons')}</Link>
                <Link href={'/over-mkg'}>{tekst('website:menu:overmkg')}</Link>
                <Link href={'/over-mkg#vacatures'}>{tekst('website:menu:vacatures')}</Link>
                <Link href={'/mijn-mkg/support'}>{tekst('website:menu:support')}</Link>
                <Link href={'/mijn-mkg/support'}>{tekst('website:menu:mijnmkg')}</Link>
            </div>
            <HamburgerOverlayMenu isTop={isTop} isMkgEu={true}/>
        </div>
    </div>


}