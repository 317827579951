"use client"
import React, {useEffect} from 'react'
import {useAxios} from "../../hooks/useAxios";
import useSWR, {useSWRConfig} from "swr";
import useGebruiker from "../../hooks/useGebruiker";


export const TagAantalActiviteiten = () =>{



    const {get} = useAxios()
    const {mutate} = useSWRConfig()
    const {namensBedrijf} = useGebruiker()

    const fetcher = async (url) => {
        const response = await get(url);
        return response.data
    };

    const keyMyActivityActionsCounter = 'activiteiten/mijn/actieVereist/aantal/' + (namensBedrijf ? namensBedrijf.id : '');
    const {data: myActivityActionsCounter} = useSWR(keyMyActivityActionsCounter, fetcher);

    useEffect(() => {
        if (!namensBedrijf) return
        mutate(keyMyActivityActionsCounter);
    }, [namensBedrijf])


    return  myActivityActionsCounter && parseInt(myActivityActionsCounter) > 0 ? <div
        className="absolute right-2 top-2 bg-kleur-geel rounded-full w-5 h-5 flex justify-center items-center text-xs">{myActivityActionsCounter}</div> : <></>
}