"use client"

import {NavDropdown} from "react-bootstrap";
import style from './DropDown.module.scss'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { IoMdArrowDropdown } from "react-icons/io";
import Image from 'next/image'
import {useAxios} from "hooks/useAxios";
import {useRouter} from 'next/navigation'
import {useEffect, useState} from "react";
import { useSWRConfig} from "swr"
export const DropDown = ({data, icon}) =>{
    const [langCookie, setLangCookie] = useState()
    const {mutate} = useSWRConfig()
    const {get} = useAxios();
    const router = useRouter();

    useEffect(() => {
        const taalCookie = document.cookie
            .split('; ')
            .find((row) => row.startsWith(`${name}=`))
            ?.split('=')[1];

        setLangCookie(taalCookie)
    }, []);
    const setTaal = (taal) =>{
        get( `/locale/${taal}`).then(response =>{
            if(response.status === 200) {
                mutate(key => true)
                window.location.reload()
            }
        })

    }

    return    <Menu as="div" className={`relative inline-block text-left 4 ${style.dropdownContainer}`}>
        <div>
            <MenuButton className="inline-flex w-full justify-center gap-x-1.5px-3 py-2 text-sm">
                {icon}
                <IoMdArrowDropdown aria-hidden="true" className="-mr-1 h-5 w-5" />
            </MenuButton>
        </div>

        <MenuItems
            transition
            className="z-[99999] absolute right-0 z-10 mt-2 w-56 origin-top-right bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        >
            <div className="py-1">

                {data && data.map(item => {
                    const {id, name, img} = item

                    return <MenuItem  key={`${id}-${name}`}>
                        <div
                            className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 cursor-pointer"
                            onClick={function () {
                                    document.cookie = 'locale=; path=/; domain=.mkg.eu; expires=Thu, 01 Jan 1970 00:00:01 GMT';
                                    document.cookie = 'locale=; path=/; domain=www.mkg.eu; expires=Thu, 01 Jan 1970 00:00:01 GMT';
                                    document.cookie = 'locale=; path=/; domain=.www.mkg.eu; expires=Thu, 01 Jan 1970 00:00:01 GMT';
                                    document.cookie = 'locale=; expires=Thu, 01 Jan 1970 00:00:01 GMT';
                                    setTaal(name.toLowerCase())
                                }}
                        >

                            <div className={`flex items-center gap-3`}>
                                <Image className={`w-[20px] h-[14px]`} src={img} alt={'location'} width={25} height={8}/>
                                <span>{name}</span>
                            </div>
                        </div>
                    </MenuItem>
                })}
            </div>
        </MenuItems>
    </Menu>


}